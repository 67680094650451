@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@media screen and (max-width: 1620px) {
    .order-place-box .blue-heading {
        font-size: 46px;
        line-height: 54px;
    }
}

@media screen and (max-width: 1599px) {
    .name-n-price h5 {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 25px;
        line-height: 34px;
        color: #0055a4;
    }
    .order-form-box .blue-heading {
        font-size: 56px;
    }
    .order-place-box .blue-heading {
        font-size: 43px;
        line-height: 54px;
    }
    header {
        width: 100%;
        padding: 32px 50px 32px 50px;
    }
}

@media screen and (max-width: 1399px) {
    .login-logo {
        text-align: center;
        padding-bottom: 24px;
    }
    .login-logo img {
        padding-bottom: 22px;
        width: 170px;
    }
    .form-check-label {
        font-family: 'Gotham';
        font-weight: normal;
        font-size: 25px;
        line-height: 33px;
        color: #fff;
        padding-left: 10px;
    }
    .form-check-input {
        width: 26px;
        height: 26px;
        border-radius: 15px;
        background: transparent;
        border: 4px solid #fff;
    }
    .form-check {
        margin: 13px 0;
    }
    .white-font-30 {
        font-family: 'Gotham';
        font-weight: normal;
        font-size: 28px;
        line-height: 65px;
        color: #fff;
        padding-bottom: 18px;
    }
    .blue-heading {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 56px;
        line-height: 60px;
        color: #0055a4;
        text-transform: uppercase;
    }
    .web-btn {
        width: 236px;
        height: 50px;
        border-radius: 27.5px;
        background: transparent;
        border: 4px solid #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 16px;
        line-height: 63px;
        text-align: center;
        color: #fff;
    }
    .for-btn-margin {
        margin-top: 40px;
    }
    header {
        padding-bottom: 3rem;
    }
    .name-n-price h4 {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 26px;
        line-height: 70px;
        color: #0055a4;
    }
    .name-n-price h5 {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 21px;
        line-height: 30px;
        color: #0055a4;
    }
    .qunty-n-remove h4 {
        font-family: 'Gotham';
        font-weight: normal;
        font-size: 17px;
        line-height: 73px;
        color: #0055a4;
    }
    .qun-input {
        width: 102px;
        height: 38px;
    }
    .order-item-details {
        margin: 20px 1rem;
    }
    .queri {
        font-size: 21px;
        letter-spacing: 0.02em;
        line-height: 30px;
        padding-bottom: 30px;
    }
    .order-place-box {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .order-place-box .blue-heading {
        font-size: 36px;
        line-height: 50px;
    }
    .numbr {
        font-size: 58px;
        letter-spacing: 0.05em;
        line-height: 35px;
    }
    header {
        padding: 27px 50px 27px 50px;
    }
    .nav-menu>li>a {
        padding: 12px 16px;
    }
    .wrapper1 .pic5 {
        max-width: 339px;
    }
    .wrapper1 .pic4 {
        max-width: 339px;
    }
    .wrapper1 .pic3 {
        max-width: 339px;
    }
    .wrapper1 .pic2 {
        max-width: 339px;
    }
    .wrapper1 .pic1 {
        max-width: 339px;
    }
    .subc-card p {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 38px;
        color: #222;
    }
    .subc-card h3 {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 50px;
        letter-spacing: -0.04em;
        line-height: 66px;
        text-align: center;
        color: #333;
    }
    .subc-card h5 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #222;
        text-shadow: 0.44px -0.9px 29px rgba(18, 25, 41, 0.13);
    }
    .months-box h5 {
        font-size: 30px;
        left: -4px;
    }
    .subc-card {
        min-height: 545px;
    }
    .subc-card h3 {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 46px;
        letter-spacing: -0.04em;
        line-height: 66px;
        text-align: center;
        color: #333;
    }
    .subc-card p {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 36px;
        color: #222;
    }
 
    .sign-up-content {
        padding: 20px 24px 20px 20px!important;
    }
    .sign-up-left {
        /* display: none; */
    }
    .sign-up-left img {
        min-height: 647px!important;
        object-fit: cover;
    }
}

@media screen and (max-width: 1199px) {
    .sign-up-left {
        display: none;
    }
    .white-font-30 {
        font-family: 'Gotham';
        font-weight: normal;
        font-size: 24px;
        line-height: 65px;
        color: #fff;
        padding-bottom: 2px;
    }
    .blue-heading {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        color: #0055a4;
        text-transform: uppercase;
    }
    .web-btn {
        width: 202px;
        height: 50px;
        border-radius: 27.5px;
        background: transparent;
        border: 4px solid #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 15px;
        line-height: 63px;
        text-align: center;
        color: #fff;
    }
    .order-place-box .blue-heading {
        font-size: 32px;
        line-height: 47px;
    }
    .order-number {
        font-family: 'Gotham';
        font-weight: normal;
        font-size: 30px;
        line-height: 18px;
        color: #0055a4;
        padding-top: 31px;
        padding-bottom: 18px;
    }
    .numbr {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 56px;
        letter-spacing: 0.05em;
        line-height: 33px;
        color: #0055a4;
        padding-top: 18px;
        padding-bottom: 20px;
    }
    .queri {
        font-size: 19px;
        letter-spacing: 0.02em;
        line-height: 28px;
    }
    .web-logo {
        width: 175px;
    }
    header {
        padding: 27px 30px 27px 30px;
    }
    .e-text-56 {
        font-family: 'Great Vibes', cursive;
        font-weight: normal;
        font-size: 42px;
        color: #2484c6;
    }
    .login-right-content {
        align-self: center;
        width: 100%;
        padding: 0px 24px 0 0;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1050px) {
    .side-nav-links {
        display: block;
    }
    .last-li {
        display: none !important;
    }
    .respn-signup {
        display: none !important;
    }
    .employess .media p {
        font-size: 8px;
    }
    .employess .media h6 {
        font-size: 10px;
    }
    .employess .media h5 {
        line-height: 42px;
    }
    .footer-hedings {
        font-size: 9px;
    }
    .footer-top ul li a {
        line-height: 20px;
        font-size: 10px;
    }
    .full-pic {
        width: 308px;
        height: 330px;
    }
    .left-box {
        padding-left: 5px;
    }
    .red-banner h5 {
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 14px;
    }
    .flip-card {
        width: 205px;
        height: 185px;
    }
    .flip-card-back img {
        width: 205px;
        height: 185px;
    }
    .area {
        padding: 60px 20px;
    }
    .practice-box .media p {
        font-size: 10px;
    }
    .practice-box .media {
        width: 205px;
        height: 185px;
    }
    .flip-card-back h5 {
        font-size: 14px;
    }
    .contact-map p {
        font-size: 9px;
        line-height: 24px;
    }
    .gray-banner h5 {
        font-size: 24px;
        line-height: 46px;
    }
    .noti-dropdown .dropdown-menu.show {
        max-width: 19rem;
    }
    .chat-nav {
        display: block!important;
    }
    .noti-content {
        max-height: 300px;
        overflow: auto;
    }
    #logout-pop {
        z-index: 99999;
    }
}

@media screen and (max-width: 991px) {
    .mango-basket {
        display: none;
    }
    .order-detail-box {
        padding-top: 0px;
        padding-bottom: 50px;
    }
    .order-detail-box .blue-heading {
        font-size: 48px;
    }
    .order-form-box .blue-heading {
        font-size: 42px;
    }
    .order-place-box {
        text-align: center;
    }
    .mango-basket2 {
        margin-top: 0rem;
        width: 300px;
    }
    .login-img-left {
        display: none;
    }
    .login-right-content {
        padding: 20px 24px 24px 20px;
    }
}

@media screen and (max-width: 767px) {
    .order-form-box .blue-heading {
        font-size: 36px;
    }
    header {
        padding: 27px 0px 27px 83px;
    }
}

@media screen and (max-width: 575px) {
    .logo-resp {
        width: 54px;
        height: 90px;
        margin-left: 2rem;
    }
    .blue-heading {
        font-size: 34px;
        line-height: 35px;
        color: #0055a4;
    }
    .white-font-30 {
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        color: #fff;
        padding: 8px 0;
    }
    .form-check-label {
        font-weight: bold;
        font-size: 17px;
        line-height: 5px;
        color: #fff;
        padding-top: 5px;
    }
    .form-check-input {
        width: 19px;
        height: 19px;
        border-radius: 9.5px;
        background: transparent;
        border: 4px solid #fff;
    }
    .form-check {
        display: inline-flex;
        align-items: center;
        margin: 8px 0;
    }
    .web-btn {
        width: 100%;
        height: 30px;
        background: transparent;
        margin: 10px 0;
        font-weight: bold;
        font-size: 9px;
        line-height: 32px;
        color: #fff;
        border: 2px solid #fff;
    }
    .for-btn-margin {
        margin-top: 10px;
    }
    /* .wrapper {
        min-height: 100vh;
        background-size: cover;
        background-position: unset;
        background-repeat: no-repeat;
        padding-bottom: 14rem;
    } */
    .wrapper {
        height: 906px;
        background-size: auto;
        background-position: top;
        /* background-repeat: no-repeat; */
        padding-bottom: 0;
        justify-content: space-between;
    }
    .order-detail-box .blue-heading {
        font-weight: bold;
        font-size: 34px;
        line-height: 35px;
        color: #0055a4;
        padding-bottom: 1rem;
    }
    .name-n-price h4 {
        font-weight: bold;
        font-size: 15px;
        line-height: 37px;
        color: #0055a4;
    }
    .qunty-n-remove h4 {
        font-weight: normal;
        font-size: 10px;
        line-height: 37px;
        color: #0055a4;
    }
    .qun-input {
        width: 62px;
        height: 22px;
        font-weight: bold;
        font-size: 11px;
        line-height: 37px;
        color: #0055a4;
        border: 2px solid #fff;
    }
    .qunty-n-remove {
        padding: 0px 0 0px 0;
    }
    .order-item-details {
        margin: 10px 0rem;
    }
    .name-n-price h5 {
        font-weight: bold;
        font-size: 15px;
        line-height: 27px;
        color: #0055a4;
    }
    .qunty-n-remove a {
        font-size: 14px;
    }
    .order-form-box .blue-heading {
        font-weight: bold;
        font-size: 32px;
        line-height: 35px;
        color: #0055a4;
        /* text-align: left!important; */
    }
    .web-input {
        /* width: 323px; */
        height: 50px;
        border: 2px solid #fff;
    }
    .order-form-box .blue-heading {
        padding-bottom: 45px;
    }
     ::placeholder {
        font-size: 8px!important;
    }
    .order-place-box .blue-heading {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        color: #0055a4;
    }
    .order-number {
        font-weight: normal;
        font-size: 18px;
        line-height: 14px;
        color: #0055a4;
        padding-top: 25px;
        padding-bottom: 18px;
    }
    .numbr {
        font-weight: bold;
        font-size: 47px;
        letter-spacing: 0.05em;
        line-height: 28px;
        color: #0055a4;
    }
    .queri {
        font-weight: normal;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 24px;
        color: #0055a4;
        padding-bottom: 20px;
    }
    .mango-basket2 {
        margin-top: 1rem;
        width: 300px;
    }
    .wrapper1 .pic5 {
        max-width: 190px;
    }
    .wrapper1 .pic4 {
        max-width: 190px;
    }
    .wrapper1 .pic3 {
        max-width: 190px;
    }
    .wrapper1 .pic2 {
        max-width: 190px;
    }
    .wrapper1 .pic1 {
        max-width: 190px;
    }
    .img-box:hover .pic3 {
        max-width: 126px;
    }
    .img-box:hover .pic2 {
        max-width: 126px;
    }
    .img-box:hover .pic1 {
        max-width: 126px;
    }
    .img-box:hover .pic4 {
        max-width: 126px;
    }
    .wrapper1 {
        justify-content: space-between;
    }
    #myVideo {
        height: 99.5vh;
    }
    .wrapper .pic5 {
        max-width: 190px;
    }
    .wrapper .pic4 {
        max-width: 190px;
    }
    .wrapper .pic3 {
        max-width: 190px;
    }
    .wrapper .pic2 {
        max-width: 190px;
    }
    .wrapper .pic1 {
        max-width: 190px;
    }
    .wrapper3 {
        justify-content: space-between;
    }
    .wrapper3 .pic5 {
        max-width: 190px;
    }
    .wrapper3 .pic4 {
        max-width: 190px;
    }
    .wrapper3 .pic3 {
        max-width: 190px;
    }
    .wrapper3 .pic2 {
        max-width: 190px;
    }
    .wrapper3 .pic1 {
        max-width: 190px;
    }
    .footer-logo {
        max-width: 190px;
    }
    .fotr-p {
        font-size: 14px;
        line-height: 26px;
    }
    .news {
        max-width: 160px;
    }
    footer {
        justify-content: space-between!important;
    }
    header {
        padding: 27px 0px 27px 5px;
    }
    /* .navigation img {
        width: 150px;
    } */
    .navigation {
        align-items: baseline!important;
    }
    .view-img-comm-box {
        max-width: 76%;
    }
    .table-resp {
        overflow: auto;
        padding: 0;
    }
    .user-listing-top div.dataTables_filter input {
        width: 270px !important;
    }
    .user-listing-top .search-wrap {
        width: 270px !important;
    }
    .about-text-box2 {
        position: unset;
        height: auto;
    }
    .about-text-box {
        position: unset;
        height: auto;
    }
    .about-text-box p {
        font-size: 14px;
    }
    .about-text-box2 p {
        font-size: 14px;
    }
    .page-heading {
        font-size: 42px;
        line-height: 32px;
    }
    .subc-card h5 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 53px;
        text-align: center;
        color: #222;
        text-shadow: 0.44px -0.9px 29px rgba(18, 25, 41, 0.13);
    }
    .subc-card h3 {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 44px;
        letter-spacing: -0.04em;
        line-height: 54px;
        text-align: center;
        color: #333;
    }
    .subc-card p {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 11px;
        line-height: 22px;
        color: #222;
    }
    .months-box h5 {
        font-size: 28px;
        left: -25px;
        top: 9rem;
    }
    .subc-card {
        min-height: 452px;
    }
    .months-box {
        width: 82px;
        height: 376px;
    }
}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 380px) {}