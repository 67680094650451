
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --primaryColor: #2484c6;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  margin:0!important;
}


body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}
.notificationHeading {
  font-size: 14px;
  font-weight: 600;
  color: #2484c6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
  /* background-color: var(--primaryColor) !important; */
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor);
}

.notificationText {
  font-size: 14px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
}
.siderWrapper{
  
  background-size: cover!important;
}
.logoBox{
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainSider{
  background: transparent!important;
}
.mainSider .ant-menu-item-selected{
  background: #00a79d!important;
  border-radius: 10px!important;
  margin:10px 0 5px 0!important;
  color:white!important;
}


.mainSider .ant-image{
  display: flex;
}

.mainSider .ant-menu-title-content{
  font-size: 16px;
}

.mainSider .ant-menu-item:hover{
  background: #00a79d!important;
  border-radius: 10px!important;
  color: white!important;
  border-radius: 50px;
  transition: 0.3s linear;
}

.mainSider .ant-menu-item{
  margin:5px 0 5px 0!important;
  font-family: 'Nunito', sans-serif!important;
  height: 50px!important;
  font-weight: bold;
  color:#333d47;
  border-radius: 10px!important;
  font-size: 14px!important;
  width: auto!important;
}

.mainSider li.ant-menu-item{
  margin:15px 0 15px 0!important;
}

.drawer .ant-menu-item-selected{
  background: #00a79d!important;
  border-radius: 0!important;
  border-right: 4px solid #2484c6;
  margin:5px 0 5px 0!important;
  color:white!important;
}

/* .drawer .ant-menu-item-selected img{
filter: invert(35%) sepia(50%) saturate(954%) hue-rotate(175deg) brightness(98%) contrast(91%);
}

.drawer .ant-menu-item:hover img{
  filter: invert(35%) sepia(50%) saturate(954%) hue-rotate(175deg) brightness(98%) contrast(91%);
 
} */

.drawer .ant-menu-item:hover{
  background: white!important;
  border-radius: 0!important;

  transition: 0.3s linear;
}

.drawer .ant-menu-item{
  margin:5px 0 5px 0!important;
  height: 48px!important;
  color:#c0c6d9;
  border-radius: 0!important;
  font-size: 14px!important;
  width: auto!important;
}

.drawer li.ant-menu-item{
  margin:5px 0 5px 0!important;
}

.drawer .ant-drawer-body{
  padding: 0;
}
.ant-drawer-content-wrapper{
  max-width: 300px;
}

.hide-on-phone {
  display: none !important;
}
.headerDropdown .icon {
color: white;
}

.ant-dropdown-menu-item:hover{
  background-color: #2484c6!important;
  color:white!important;
  border-radius: 0!important;
}


 .ant-popover-inner{
  padding:0!important;
  border-radius: 5px!important;
  border:1px solid rgb(0 0 0 / 15%)!important;
}

.headerPopover .ant-popover-inner{
  padding:0!important
}

.ant-dropdown .ant-dropdown-menu{
  border-radius: 0!important;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}


