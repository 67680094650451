@font-face {
    font-family: 'Harrington';
    src: url('Harrington.eot');
    src: url('Harrington.eot?#iefix') format('embedded-opentype'),
        url('Harrington.woff2') format('woff2'),
        url('Harrington.woff') format('woff'),
        url('Harrington.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GoodVibrations';
    src: url('GoodVibrationsScript.eot');
    src: url('GoodVibrationsScript.eot?#iefix') format('embedded-opentype'),
        url('GoodVibrationsScript.woff2') format('woff2'),
        url('GoodVibrationsScript.woff') format('woff'),
        url('GoodVibrationsScript.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/* 
 font-family: 'Open Sans', sans-serif; */